<template>
  <div class="modal-background">
    <div class="modal-content" v-if="subscriptionStatus == '1'">
      <button class="modal-close-button" @click="closeModal">&#x2715</button>
        <h2>Подписка оформлена <br>&#128512;</h2>
        <button class="btn" @click="$emit('clickPlay')">Игать</button>
    </div>
    <div class="modal-content" v-else>
      <button class="modal-close-button" @click="closeModal">&#x2715</button>
        <h2>Упс. К сожалению, не получилось купить подписку &#128546;
          <br> Попробуйте позднее </h2>
        <button class="btn" @click="$emit('clickPlay')">Игать без подписки</button>
    </div>
  </div>

</template>

<script>
export default {
  name: "SubscriptionStatusModal",
  props:['subscriptionStatus'],
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
}
</script>

<style scoped>
/* Стили для фонового затемнения */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Затемнение фона */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Выше всех остальных элементов */
}

/* Стили для модального окна */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  position: absolute;
  font-family: "Montserrat", sans-serif;
}

/* Заголовок модального окна */
.modal-content h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #3d3a3a;
  padding-top: 20px;
  line-height: 1.5;
  font-weight: 600;

}

/* Кнопка закрытия модального окна */
.modal-close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #000; /* Цвет ссылки */
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 10px;
  position: absolute;
  right: 20px;
  top: 10px;
}

/* Скрытие модального окна по умолчанию */
.modal-content {
  max-width: 90%;
}

/* Отображение модального окна при активации */
.modal-background.show {
  display: flex;
}
</style>