<template>
  <GameMain />
</template>

<script>
import axios from "axios";
import GameMain from "@/components/game/GameMain";

export default {
  name: "Game",
  components: {
    GameMain,
  },
};
</script>

<style>
</style>