<template>
  <div class="start-game" ref="startGame">
    <h1 class="start-game__title" ref="startGameTitle">Собирайте строчки из слов и угадывайте песни</h1>
    <div class="start-game__info" ref="startGameInfo">

      <div class="main-image-wrapper">
        <img class="main-image" src="../../assets/MuzErudit.jpg" />
      </div>
      <div class="start__buttons">
            <span class="start__buttons_video" @click="$bus.$emit('showInstuction', true)">
              <IconGraduationCap
                  :width="20"
                  :height="16"
                  color="#a996ff"
              />Обучающее видео с правилами игры
            </span>
        <button
            class="btn start-game__btn"
            @click="$emit('clickPlay')"
            v-text="'Играть'"
        />
        <button
            v-if="isAdmin"
            class="btn start-game__btn btn-gradient"
            @click="$router.push('/admin')"
            v-text="'Админ-панель'"
        />
      </div>
    </div>
  </div>

</template>

<script>
import IconGraduationCap from '@/components/icons/IconGraduationCap';
export default {
  name: "DefaultUser",
  components: {
    IconGraduationCap,
  },
  props: ['isAdmin']
}
</script>

<style lang="scss" scoped>
.main-image-wrapper
{
  background-color: #FFFFFF;
  padding: 5px;
  border-radius: 20px;
  margin: 0 25px 50px;
}
.main-image{
  width: 100%;
  border-radius: 20px;
}
.start-game__info {
  justify-content: center;
}
</style>
