<template>
  <div class="start-game" ref="startGame">
    <h1 class="start-game__title" ref="startGameTitle">Собирайте строчки песен<br/> и выигрывайте призы</h1>
    <div class="start-game__info" ref="startGameInfo">

      <div v-if="lodash.size(selectedStock)" class="start-game__stock">
        <p class="start-game__description">
          «{{selectedStock.name}}»
          <br/>
          {{getStockDates(selectedStock)}}
        </p>
        <GamePrizes :prizesByStock="prizesBySelectedStock"/>
      </div>
      <div v-else-if="lodash.size(lastEndedStock)" class="start-game__stock">
        <p
            class="start-game__description"
            @click="$router.push('/top')"
        >
          Результаты
          <br/>«{{lastEndedStock.name}}»
          <br/>{{getStockDates(lastEndedStock)}}
        </p>
        <GamePrizes :prizesByStock="emptyStock.show ? emptyStock.prizes : {}"/>
      </div>
      <div v-else class="start-game__stock">
        <p class="start-game__description">
          Следующий розыгрыш состоится в ближайшее время. Следите за объявлениями в игре!
        </p>
        <GamePrizes :prizesByStock="emptyStock.show ? emptyStock.prizes : {}"/>
      </div>

      <div class="start__buttons">
            <span class="start__buttons_video" @click="$bus.$emit('showInstuction', true)">
              <IconGraduationCap
                  :width="20"
                  :height="16"
                  color="#a996ff"
              />Обучающее видео с правилами игры
            </span>
        <button
            v-if="hasSubscription"
            class="btn start-game__btn"
            @click="$emit('clickPlay')"
            v-text="'Играть'"
        />
        <a
            v-if="!hasSubscription"
            class="btn start-game__btn start-game__btn_green"
            :href="subscriptionUrl"
            v-text="'Купить подписку'"
        />
        <button
            v-if="!hasSubscription"
            class="btn  start-game__btn "
            @click="$emit('clickPlay')"
            v-text="'Играть без подписки'"
        />
        <button
            v-if="[1, 2].includes(user.role)"
            class="btn start-game__btn btn-gradient"
            @click="$router.push('/admin')"
            v-text="'Админ-панель'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GamePrizes from './GamePrizes.vue';
import IconMusicSolid from '@/components/icons/IconMusicSolid';
import IconGraduationCap from '@/components/icons/IconGraduationCap';
import _, {has} from 'lodash';
import {mapActions, mapGetters} from "vuex";
export default {
  name: "MegafonUser",
  components:{
    GamePrizes,
    IconMusicSolid,
    IconGraduationCap},
  computed: {
    ...mapGetters([
      'prizesBySelectedStock',
      'emptyStock',
      'selectedStock',
      'lastEndedStock',
      'orientation',
      'user',
      'token'
    ]),
  },
  props:['isAdmin', 'hasSubscription'],
  data: () => ({
    lodash: _,
    startGameAfterInstruction: false,
    // isShowMusicSolid: true,
    subscriptionBaseUrl : "https://lp.advmusic.com/muzscrabble/",
    subscriptionUrlparams:{
      opgroup: "megafon-ru",
      service: "megafon-muzscrabble",
      return_url:  window.location.origin + '?origin=megafon-lp',
      extra: "muzerudit_web"
    },
    subscriptionUrl: "",
  }),
  mounted() {
    this.getSubsctiptionUrl();

  },
  methods: {
    ...mapActions([
      'checkGameDuration',
      'getUserStaticData',
    ]),

    getSubsctiptionUrl(){
      var url = new URL(this.subscriptionBaseUrl);
      // Добавляем параметры к объекту URL
      for (var key in this.subscriptionUrlparams) {
        if (this.subscriptionUrlparams.hasOwnProperty(key)) {
          url.searchParams.set(key, this.subscriptionUrlparams[key]);
        }
      }
      const decodedUrl = decodeURIComponent(url.href);
      this.subscriptionUrl = decodedUrl;
    },

    getStockDates(stock) {
      const start = this.convertDate(stock.start_date);
      const end = this.convertDate(stock.end_date);
      return `${start} - ${end}`;
    },

    convertDate(unix) {
      const date = new Date(unix);
      const day = date.getDate().toString().padStart(2, 0);
      const month = (date.getMonth() + 1).toString().padStart(2, 0);
      const year = date.getFullYear().toString().slice(-2);
      return `${day}.${month}.${year}`;
    }
  },
}



</script>

<style lang="scss" scoped>

.start-game__stock {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.start-game__info h2 {
  color: #14142B;
}

.start-game__description {
  color: #14142B;
  text-align: center;
  line-height: 18px;
  font-size: 15px;
  border-radius: 10px;
  background-color: #fff;
  margin: 25px 25px -10px 25px;
  padding: 10px;
  font-weight: bold;
}

.start-game__info h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
  color: #14142B;
}
.start-game__btn_green{
  background-image: linear-gradient(to right top, #008793, #00bf72);
  color: #FFFFFF !important;
  border-color: #fff !important;
  text-align: center;
  line-height: 2.5;
}
</style>