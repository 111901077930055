<template>
  <div class="prizes" :key="lodash.size(prizesByStock)">
    <VueSlickCarousel v-if="lodash.size(prizesByStock)"
                      :arrows="false"
                      :dots="true"
                      :centerMode="true"
                      :slidesToShow="2"
                      :focusOnSelect="true"
                      :centerPadding="'25px'"

    >

      <div
          v-for="prize, id in prizesByStock"
          :key="id"
          class="prize-item"
      >
        <div class="prize-item__image">
          <img :src="storageUrl + prize.img" :alt="prize.name">
        </div>
        <p
            class="prize-item__text"
            v-text="prize.description"
        />
      </div>
    </VueSlickCarousel>
    <IconMusicSolid
      v-else
      style="width: 100%; position: absolute; top: calc(50% - 50px);"
      color="#a894ff40"
      size="100"
    />

  </div>
</template>

<script>
import _ from 'lodash';
import IconMusicSolid from '@/components/icons/IconMusicSolid';
import { mapGetters } from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'GamePrizes',

  components: { 
    IconMusicSolid,
    VueSlickCarousel
  },

  props: {
    prizesByStock: {
      type: [Object],
      required: true,
      default: () => ({}),
    },
  },

  data: () => ({
    lodash: _,
  }),

  computed: {
    ...mapGetters(['storageUrl']),
  },
};
</script>

<style>
.prizes {
  margin: 25px 25px 0 25px;
  height: 100%;
  position: relative;
}

.prizes__body {
  display: flex;
}

.prize-item__image{
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  margin: 0 5px;
}

.prize-item__image img {
 
  width: 100%;
}

.prize-item__text {
  color: black;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  margin-top: 15px;
  white-space: break-spaces;
}

</style>