<template>
  <section class="start">
    <div class="portrait" v-if="user.login !== 'guest'">

      <SubscriptionStatusModal 
        v-if="isShownSubscriptionStatusModal"
        :subscriptionStatus = subscriptionStatus
        @closeModal="closeSubscriptionStatusModal"
        @clickPlay="onClickPlay"
      />

      <div v-if="isDefaultUser" class="start-game__wrapper">
        <DefaultUser
          :isAdmin="[1, 2].includes(user.role)"
          @clickPlay="onClickPlay"
        />
      </div>
      <div v-else class="start-game__wrapper">
        <MegafonUser
          :isAdmin="[1, 2].includes(user.role)"
          :hasSubscription="isMegafonWithSubscriptionUser"
          @clickPlay="onClickPlay"
        />
      </div>
    </div>
  </section>
</template>


<script>
import _ from 'lodash';
import axios from 'axios';
import DefaultUser from "./DefaultUser.vue";
import MegafonUser from "./MegafonUser.vue";
import { mapGetters, mapActions } from 'vuex';
import SubscriptionStatusModal from "@/components/game/SubscriptionStatusModal.vue";

export default {
  name: 'GameMain',

  components: {
    DefaultUser,
    MegafonUser,
    SubscriptionStatusModal
  },

  data: () => ({
    lodash: _,
    training: false,
    startGameAfterInstruction: false,
    // isShowMusicSolid: true,
    isShownSubscriptionStatusModal: false,
    subscriptionStatus: 0
  }),

  computed: {
    ...mapGetters([
      'prizesBySelectedStock',
      'emptyStock',
      'selectedStock',
      'lastEndedStock',
      'orientation', 
      'user', 
      'token',
      'isDefaultUser',
      'isMegafonWithSubscriptionUser',
      'isMegafonWithoutSubscriptionUser',
    ]),
  },

  mounted() {
    this.checkGameDuration();
    if (this.user.login === 'guest') {
      this.startGameAfterInstruction = true;
      this.$bus.$emit('showInstuction', true);
      this.$bus.$on('exitInstuction', () => {
        if (!this.startGameAfterInstruction) return;
        this.$router.push('/demo');
      });
    } else {
      this.$store.dispatch('updateSubscription');
    };

    // Проверяем редирект с ледндига и стаутус покупки
    const params = new URLSearchParams(window.location.search);
    this.subscriptionStatus = params.get('success');

    if (this.subscriptionStatus == 1) {
      this.updateUserCategory('megafonSubscription');
    };

    if (params.get('origin') === 'megafon-lp' ) {
      this.isShownSubscriptionStatusModal = true;
    };
  },

  methods: {
    ...mapActions([
      'checkGameDuration',
      'getUserStaticData',
      'updateUserCategory',
    ]),

    closeSubscriptionStatusModal(){
      this.isShownSubscriptionStatusModal = false;
    },
    getStockDates(stock) {
      const start = this.convertDate(stock.start_date);
      const end = this.convertDate(stock.end_date);
      return `${start} - ${end}`;
    },

    convertDate(unix) {
      const date = new Date(unix);
      const day = date.getDate().toString().padStart(2, 0);
      const month = (date.getMonth() + 1).toString().padStart(2, 0);
      const year = date.getFullYear().toString().slice(-2);
      return `${day}.${month}.${year}`;
    },

    async onClickPlay() {
      this.startGameAfterInstruction = false;
      if (_.get(this.user, 'instruction.show')) return this.openGame();
      this.startGameAfterInstruction = true;
      this.$bus.$emit('showInstuction', false, true);
      this.getUserStaticData(_.defaultsDeep({ instruction: { show: true } }, this.user));
      const data = { show: true };
      const headers = { token: this.token };
      await axios.put('/api/user/instruction', data, { headers });
      this.$bus.$on('exitInstuction', () => {
        if (!this.startGameAfterInstruction) return;
        this.openGame();
      });
    },

    openGame() {
      this.$metrika.add(this.$metrika.consts.GAME_START);
      this.$router.push('/play');
    },
  },
};
</script>


<style lang="scss">
.start-game__wrapper {
  height: 100%;
  width: 100%;
}

.start {
  width: 100%;
  height: 100%;

  .portrait {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .logotype {
      font-size: 32px;
      padding-top: 120px;
      padding-bottom: 30%;
    }
  }

  &__container {
    width: 100%;

    button:first-child {
      margin-bottom: 20px;
    }
  }
}
.start__buttons {
  display: flex;
  flex-direction: column;
  gap: 1em;
  &_video {
    color: rgb(169, 150, 255);
    text-align: center;
    text-decoration: underline; 
    width: 100%;
    cursor: pointer;
  }
  button.btn-gradient {
    height: 40px !important;
    line-height: 1em;
  }
}


.video-training{
margin: 0 0 25px 0;
}
.video-training__item{
  display: block;
  margin: 0 auto;
  cursor:pointer;
}

.start-game {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.start-game__btn{
  margin: 0 auto;
  width: calc(100% - 50px)!important;
  display: block;
  height: 70px !important;
  font-size: 18px !important;
}

.start-game__title {
  color: #14142B;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  //padding: 45px 10px 35px;
  padding: 45px 10px 10px;
}

.start-game__warning{
  color: crimson;
  text-align: center;
  padding: 0px 10px 15px;
}

.start-game__info {
  background-color: #DCD5FF;
  margin: 0;
  padding:0 0 40px 0;
  border-radius: 20px 20px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

</style>